.root {
  background-color: var(--color-white);
  font-family: var(--manrope);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
}

& .breadcrumbs {
  margin-bottom: 20px;
}

& .text {
  margin-bottom: 20px;
  color: var(--color-carbone-base);
}

& .grid {
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-areas: 'subtitle links';
  margin-bottom: 20px;

  @media (--mobile) {
    grid-template-columns: 1fr;
    grid-template-areas: 'subtitle' 'links';
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);
  margin-bottom: 10px;
}

.subtitle {
  grid-area: subtitle;

  @media (--mobile) {
    margin-bottom: 5px;
    font-weight: var(--font-weight-bold);
  }
}

& .links {
  display: flex;
  flex-wrap: wrap;
  grid-area: links;
}

.link {
  color: var(--color-cloud-gray-dark);
  transition: color var(--transition-duration);
  margin-right: 2px;

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  &:active {
    color: var(--color-blue-dark);
  }

  &.lowercase {
    text-transform: lowercase;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.separator::after {
  content: ',';
}
