.root {
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-blue);
  position: relative;
  overflow: hidden;
  max-width: 670px;
  margin: 0 0 20px;

  &.mobile {
    margin: 0 var(--mobile-horizontal-padding) 20px;
  }
}

.title {
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  color: var(--color-white);
  margin-bottom: 15px;
}

.button {
  max-width: 210px;
  z-index: 10;
  color: var(--color-blue-base) !important;

  &:hover,
  &:focus {
    color: var(--color-blue-middle) !important;
  }

  &:active {
    color: var(--color-blue-dark) !important;
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 150px;
  width: 487px;
}
