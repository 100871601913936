.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 0 5px;
  margin-bottom: 5px;
  border-radius: 5px;

  &.full {
    margin-bottom: 15px;
  }

  &.small {
    width: 310px;
  }

  &.withImage {
    padding: 10px 5px;
    border-top: 1px solid var(--color-alt-blue-2);
    border-bottom: 1px solid var(--color-alt-blue-2);
    border-radius: 0;
    align-items: center;

    & + .withImage {
      padding-top: 0;
      margin-top: -5px;
      border-top: none;
    }
  }

  &.for-episode {
    margin-bottom: 15px;
    padding: 0;
  }

  &.for-show {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 0;
  }

  &.sports {
    background-color: var(--color-green-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.kids {
    background-color: var(--color-pink);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.series {
    background-color: var(--color-alt-blue-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.movies {
    background-color: var(--color-yellow-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }
}

.dateStart {
  display: flex;
  flex-shrink: 0;
  width: 105px;
  margin-right: 15px;
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);

  &.small {
    margin-right: 5px;
  }

  .for-episode & {
    font-size: var(--font-size-small);
  }

  .for-show & {
    width: auto;
    margin-right: 5px;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-small);
  }
}

.weekend {
  color: var(--color-red-base);

  &::first-letter {
    text-transform: uppercase;
  }
}

.timeStart {
  width: 35px;
  flex-shrink: 0;
  margin-right: 15px;
  font-family: var(--manrope);
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);

  .full & {
    width: 40px;
  }

  .small & {
    margin-right: 5px;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-default);
    font-weight: var(--font-weight-default);

    &.active {
      color: var(--color-carbone-base);
    }
  }

  .for-show & {
    width: auto;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-small);
  }
}

.img {
  margin-right: 15px;
  border-radius: 5px;
}

.title {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .for-show & {
    width: 100%;
    margin-top: 5px;
  }

  .small & {
    .progress {
      margin-bottom: 5px;
    }
  }
}

.content {
  display: flex;
  align-items: center;
}

.text {
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);

  .small & {
    font-weight: var(--font-weight-default);
    font-size: var(--font-size-small);
    line-height: var(--line-height-default);

    &.active {
      font-weight: var(--font-weight-bold);
    }

    .root:hover &,
    .root:focus & {
      color: var(--color-blue-base);
    }

    .root:active & {
      color: var(--color-blue-dark);
    }
  }

  .for-show & {
    font-size: var(--font-size-small);
    line-height: var(--line-height-default);
  }

  &.active {
    font-weight: var(--font-weight-bold);
  }

  .root:hover &,
  .root:focus & {
    color: var(--color-blue-base);
  }
}

.live {
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  flex-shrink: 0;
  margin-left: 5px;
  background-color: var(--color-red-base);
  border-radius: 50%;
}
