.root {
  display: grid;
  grid-template-columns: repeat(4, minmax(220px, 1fr));
  grid-column-gap: 20px;

  @media (--mobile) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}

.item {
  display: grid;
  grid-template-rows: 125px auto;
  grid-row-gap: 10px;
  color: var(--color-carbone-base);
  transition: color var(--transition-duration);

  @media (--mobile) {
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    grid-template-rows: auto;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  &:active {
    color: var(--color-blue-dark);
  }
}

.image {
  border-radius: 5px;
  height: 125px;
  width: 100%;
  object-fit: cover;

  @media (--mobile) {
    height: 90px;
    width: 90px;
  }
}

.data {
  display: grid;
  grid-row-gap: 5px;
  align-content: start;
}

.title {
  font-family: var(--manrope);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-default);
  height: fit-content;
}

.info {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
}
