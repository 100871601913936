.root {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 10px;

  .mobile & {
    margin-right: 20px;
  }
}

.title {
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);

  .small & {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-default);
    line-height: var(--line-height-default);
  }
}

.icon {
  opacity: 0;

  .root:hover &,
  .root:focus & {
    opacity: 1;
  }

  @media (--mobile) {
    opacity: 1;
    margin-left: auto;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
