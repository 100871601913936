.root {
  display: flex;
  align-items: center;
  font-family: var(--manrope);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
  color: var(--color-cloud-gray-dark);

  &.button {
    height: 30px;
    padding: 5px 10px;
    color: var(--color-white);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-extra-bold);
    border-radius: 15px;
    background-color: var(--color-blue-base);
    cursor: pointer;

    .count {
      margin-left: 5px;
    }
  }
}
