.container {
  padding: 20px 10px;
  background: var(--white);
  margin-bottom: 20px;

  @media (--mobile) {
    border-top: 1px solid var(--color-alt-blue-3);
    border-bottom: 1px solid var(--color-alt-blue-3);
  }

  @media (--desktop) {
    max-width: 300px;
    padding: 15px 15px 20px;
    border-radius: 15px;
    box-shadow: 0 5px 30px -10px rgb(49 94 251 / 0.3);
  }
}

.columns {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.icon {
  width: 80px;
  height: 80px;
  margin-right: 20px;

  @media (--desktop) {
    width: 70px;
    height: 70px;
    margin-right: 15px;
  }
}

.info {
  font-weight: var(--font-weight-default);
  width: calc(100% - 80px - 20px);

  p {
    font-weight: inherit;
  }

  @media (--desktop) {
    width: calc(100% - 70px - 15px);
  }
}

.heading {
  margin-bottom: 5px;
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
}

.button {
  margin-top: 15px;

  @media (--mobile) {
    margin-left: calc(80px + 20px);
  }
  @media (--desktop) {
    width: 100%;
  }
}
