.root {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 3px;
  margin-top: 7px;
  background-color: var(--color-alt-blue-4);
  border-radius: 5px;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--color-blue-base);
  transition: width var(--transition-duration);
  border-radius: 5px;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
