.root {
  font-family: var(--manrope);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
  display: flex;
  flex-wrap: wrap;

  & li > span {
    color: var(--color-cloud-gray-dark);
    white-space: nowrap;
  }

  & li:last-child > span {
    white-space: normal;
  }
}

.link {
  color: var(--color-cloud-gray-dark);
  transition: color var(--transition-duration);
  white-space: nowrap;

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  &:active {
    color: var(--color-blue-dark);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.separator::after {
  content: '\00a0\00a0\00a0\00a0 / \00a0\00a0\00a0\00a0';
  color: var(--color-carbone-base);
}
