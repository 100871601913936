.button {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 5px;
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-carbone-base);
  transition:
    opacity var(--transition-duration),
    color var(--transition-duration);

  @media (--desktop) {
    margin-right: 10px;
  }

  &:focus {
    color: var(--color-carbone-base);
  }

  &:hover {
    color: #fc2772;
  }

  &:active {
    color: #c91f5b;
  }

  &.isFavorite {
    opacity: 1;
    color: #fc2772;

    &:hover,
    &:focus {
      color: #e32366;
    }

    &:active {
      color: #c91f5b;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
