.root {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-alt-blue-2);

  @media (--monitor) {
    flex-direction: column;
    width: 100%;
  }

  h1 {
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
  }

  &.mobile {
    width: 100%;
    flex-direction: column;
    border-bottom: none;

    h1 {
      padding: 0 var(--mobile-horizontal-padding);
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;

  @media (--monitor) {
    flex-direction: column;
  }
}

.authWidget {
  margin-top: -10px;
}

.moreButton {
  margin: 0 auto 20px;

  .mobile & {
    margin: 20px 10px auto;
    width: calc(100% - 20px);
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 315px;
  margin-right: 20px;
  margin-bottom: 30px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (--monitor) {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .mobile & {
    width: 100%;
    margin-right: 0;
    padding: 0 var(--mobile-horizontal-padding);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cover {
  overflow: hidden;
  flex-shrink: 0;
  width: 315px;
  height: 180px;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: var(--color-alt-blue-1);

  @media (--monitor) {
    width: 300px;
    height: 170px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .mobile & {
    width: 100%;
    height: 195px;
  }
}

.img {
  width: 100%;
  height: 100%;

  .mobile & {
    object-fit: cover;
    object-position: center;
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media (--monitor) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;

  @media (--monitor) {
    margin-bottom: 0;
  }

  .mobile & {
    margin-bottom: 15px;
  }
}

.titleLink {
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  .mobile & {
    margin-right: auto;
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }
}

.description {
  margin-bottom: 10px;

  .mobile & {
    margin-bottom: 15px;
  }
}

.descriptionText {
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-size: var(--font-size-default);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-default);

  .mobile & {
    font-size: var(--font-size-ml);
  }
}

.favorite {
  position: relative;
  margin-right: 20px;

  .mobile & {
    margin-right: 10px;
  }
}

.info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dateLabel {
  margin: 0 20px;
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
}

.dropdownWrapper {
  position: relative;

  .mobile & {
    margin-left: auto;
  }
}

.dropdown {
  z-index: 10;
  position: absolute;
  top: 30px;
  left: 0;
  width: 340px;
  padding: 20px 20px 5px;
  background-color: var(--color-white);
  border-radius: 15px;
  box-shadow: 0 10px 30px -5px rgba(49 94 251 / 0.25);

  .mobile & {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 5px;
    width: calc(100% - 10px);
    padding: 25px 15px 10px;
    border-radius: 15px 15px 0 0;
    transition: transform 200ms;
    transform: translate3d(0, 100%, 0);

    &.isOpen {
      transform: translate3d(0, 0, 0);
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  }
}

.dropdownClose {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--color-carbone-base);
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .mobile & {
    right: 10px;
    top: 10px;
  }
}

.dropdownItem {
  margin-bottom: 15px;
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);

  &:last-child {
    margin-bottom: 0;
  }

  .mobile & {
    font-size: var(--font-size-default);
  }

  .dateLabel {
    margin: 0;
    margin-bottom: 5px;
    color: var(--color-cloud-gray-dark);
    font-size: var(--font-size-x-small);
    font-weight: var(--font-weight-default);
    line-height: var(--line-height-small);

    .mobile & {
      font-size: var(--font-size-small);
      line-height: var(--line-height-default);
    }
  }
}

.sessions {
  font-family: var(--manrope);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;

  span {
    text-indent: -9999px;
    color: var(--color-blue-base);
  }

  &:focus {
    span {
      color: var(--color-blue-middle);
    }
  }

  &:hover {
    span {
      color: var(--color-blue-middle);
    }
  }

  @media (--monitor) {
    span {
      text-indent: 0;
    }

    svg {
      display: none;
    }
  }
}

.link {
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-default);
  display: flex;
  margin: 20px 0 25px;
}

.tags {
  flex-wrap: nowrap !important;
}

.content {
  @media (--monitor) {
    /* 315px - размер изображение на десктопе, 20px отступ */
    width: calc(100% - 335px);
  }
}
