.root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.negativeMargin {
    margin-bottom: -10px;
  }
}

.tag {
  background-color: var(--color-alt-blue-2);
  color: var(--color-carbone-base);
  font-weight: var(--font-weight-default);
  white-space: nowrap;
  border-radius: 25px;
  margin-right: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;

  &.greenTag {
    background-color: var(--color-green-base);
    color: var(--color-white);
  }
}

.icon {
  color: var(--color-cloud-gray-dark);
  border: 1px solid var(--color-cloud-gray-dark);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-x-small);
  margin-bottom: 10px;
}
