.root {
  padding: 0 !important;
  height: 30px;

  & > span {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-extra-large);
    font-weight: var(--font-weight-extra-bold);
    line-height: var(--line-height-extra-large);
    color: var(--color-blue-base);
    transition: color var(--transition-duration);

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  &:hover,
  &:focus {
    & > span {
      color: var(--color-blue-middle);
    }
  }

  &:active span {
    color: var(--color-blue-middle) !important;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: var(--color-alt-blue-2);
  border-radius: 50%;
  margin-left: 5px;
}
