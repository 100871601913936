.root {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 5px 30px -10px rgba(49 94 251 / 0.3);

  &.mobile {
    width: 100%;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }
}

.title {
  margin-bottom: 15px;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-extra-bold);

  .mobile & {
    margin-bottom: 20px;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.itemNews {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.link {
  margin-bottom: 5px;
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-default);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mobile & {
    font-size: var(--font-size-medium-s);
    line-height: var(--line-height-medium-s);
  }
}

.info {
  display: flex;
  font-family: var(--manrope);
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
}

.comments {
  margin-left: 10px;
}
